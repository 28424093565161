import React from 'react'
import Modal from 'react-modal'
import { Route, Routes } from 'react-router-dom'
import Current from '../Main/Components/ANDRIOD/ONLINE/Current'
import Plan from '../Main/Components/ANDRIOD/QUEUE/Plan'
import Protocol from '../Main/Components/ANDRIOD/PROTOCOLS/Protocol'
import Tests from '../Main/Components/ANDRIOD/TESTS/Tests'
import Online from '../Main/Components/CONVERSATIONS/ONLINE/Online'
import Telegramm from '../Main/Components/CONVERSATIONS/TELEGRAM/Telegramm'
import Whatsapp from '../Main/Components/CONVERSATIONS/WHATSAPP/Whatsapp'
import NavBar from '../Main/Components/NavBar/NavBar'
import TableIP from '../Main/Components/NETWORK/LTE/TableIP'
import Wifi from '../Main/Components/NETWORK/WIFI/Wifi'
import Activate from '../Main/Components/SIM/ACTIVATE/Activate'
import Scan from '../Main/Components/SIM/SCAN/Scan'
import Sms from '../Main/Components/SIM/SMS/Sms'
import Accounts from '../Main/Components/STATS/ACCOUNTS/Accounts'
import Main from '../Main/Main'
import AddMode from '../Main/Components/SIM/ADDMODE/AddMode'

Modal.setAppElement('#root')
function App(): JSX.Element {
	return (
		<>
			<NavBar />
			<Routes>
				<Route path="/" element={<Main />} />
				<Route path="/network">
					<Route path="lte" element={<TableIP />} />
					<Route path="wifi" element={<Wifi />} />
				</Route>
				<Route path="/andriod">
					<Route path="current" element={<Current />} />
					<Route path="plan" element={<Plan />} />
					<Route path="protocol" element={<Protocol />} />
					<Route path="tests" element={<Tests />} />
				</Route>
				<Route path="/conversations">
					<Route path="online" element={<Online />} />
					<Route path="telegram" element={<Telegramm />} />
					<Route path="whatsapp" element={<Whatsapp />} />
				</Route>
				<Route path="/sim">
					<Route path="activate" element={<Activate />} />
					<Route path="scan" element={<Scan />} />
					<Route path="sms" element={<Sms />} />
					<Route path="add-mode" element={<AddMode />} />
				</Route>
				<Route path="/stats">
					<Route path="accounts" element={<Accounts />} />
				</Route>
			</Routes>
		</>
	)
}

export default App
