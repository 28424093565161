/* eslint-disable @typescript-eslint/no-misused-promises */
import 'animate.css'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {
	useAppDispatch,
	useAppSelector,
} from '../../../../../hooks/redux-hooks'
import { allIps } from '../../../../../store/slices/ipSlice/ipSlice'
import type {
	IpTable,
	IpTableType,
} from '../../../../../store/slices/ipSlice/ipTypes'

export default function TableIP(): JSX.Element {
	const ip = useAppSelector(state => state.ips.ips)
	const dispatch = useAppDispatch()
	const [all, setAll] = useState(false)
	const [checkboxes, setCheckboxes] = useState<string[]>([])
	const [state, setState] = useState<IpTableType[]>([])
	const [tables, setTables] = useState<string[]>([])
	const [update, setUpdate] = useState(false)
	const [page, setPage] = useState(30)
	useEffect(() => {
		dispatch(allIps())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		axios<IpTable>(
			'https://atom.terminator.systems/api/v7/network/lte/list?token=57478bdd6cc0d1aeef78b98f8f1a4220',
		)
			.then(res => {
				setTables([...res.data.columns])
				setState([...res.data.data.slice(page - 30, page)])
			})
			.catch(console.log)
	}, [update, page])

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		all ? setCheckboxes(ip.data.map(el => el.name)) : setCheckboxes([])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [all])
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const changeCheckboxes = (id: string) => {
		setCheckboxes(
			checkboxes.includes(id)
				? checkboxes.filter(el => el !== id)
				: [...checkboxes, id],
		)
	}
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const updateIpCheck = async (id: string[]): void => {
		const filtered = ip.data.filter(el => id.some(key => el.name === key))
		const filtered2 = state.filter(el => id.every(key => el.name !== key))
		const res = await axios<IpTable>(
			'https://atom.terminator.systems/api/v7/network/lte/list?token=57478bdd6cc0d1aeef78b98f8f1a4220',
		)

		const rowData = res.data.data.filter(el =>
			filtered.some(key => el.name === key.name),
		)
		if (rowData) {
			setState([...rowData, ...filtered2])
		}
	}

	const sortExternalPingFunc = (): void => {
		// const res = ip.data.sort(
		// 	(a, b) => Number(b.external_ping) - Number(a.external_ping),
		// )
		// setState([...res])
		setState([
			...state.sort(
				(a, b) => Number(b.external_ping) - Number(a.external_ping),
			),
		])
	}

	const sortLocalPingFunc = (): void => {
		setState([
			...state.sort((a, b) => Number(b.local_ping) - Number(a.local_ping)),
		])
	}

	const clearCheckboxes = (): void => {
		setCheckboxes([])
		setAll(!all)
	}

	const changePageHandlerPlus = (): void => {
		setPage(prev => prev + 30)
	}
	const changePageHandlerMinus = (): void => {
		setPage(prev => prev - 30)
	}

	return (
		<div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-around',
					padding: '5px',
				}}
			>
				{checkboxes.length !== 0 ? (
					<>
						<button
							style={{
								backgroundColor: '#4CAF50',
								borderRadius: '10px',
								color: 'white',
								padding: '6px 32px',
								textAlign: 'center',
								textDecoration: 'none',
								display: 'inline-block',
								fontSize: '16px',
							}}
							type="button"
							onClick={() => updateIpCheck(checkboxes)}
						>
							Обновить выбранные
						</button>
						<button
							style={{
								backgroundColor: '#4CAF50',
								borderRadius: '10px',
								color: 'white',
								padding: '15px 32px',
								textAlign: 'center',
								textDecoration: 'none',
								display: 'inline-block',
								fontSize: '16px',
							}}
							type="button"
							onClick={() => clearCheckboxes()}
						>
							Очистить выбранные
						</button>
					</>
				) : null}
				<button
					style={{
						backgroundColor: '#4CAF50',
						borderRadius: '10px',
						color: 'white',
						padding: '15px 32px',
						textAlign: 'center',
						textDecoration: 'none',
						display: 'inline-block',
						fontSize: '16px',
					}}
					type="button"
					onClick={() => setUpdate(!update)}
				>
					Обновить все
				</button>
				<button
					style={{
						backgroundColor: '#4CAF50',
						borderRadius: '10px',
						color: 'white',
						padding: '6px 32px',
						textAlign: 'center',
						textDecoration: 'none',
						display: 'inline-block',
						fontSize: '16px',
					}}
					type="button"
					onClick={() => sortLocalPingFunc()}
				>
					Сортировать по local ping
				</button>
				<button
					style={{
						backgroundColor: '#4CAF50',
						borderRadius: '10px',
						color: 'white',
						padding: '6px 32px',
						textAlign: 'center',
						textDecoration: 'none',
						display: 'inline-block',
						fontSize: '16px',
					}}
					type="button"
					onClick={() => sortExternalPingFunc()}
				>
					Сортировать по external ping
				</button>
			</div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					paddingLeft: '50px',
				}}
			>
				<table
					style={{
						display: 'block',
						overflowX: 'auto',
						whiteSpace: 'nowrap',
						fontFamily: 'Arial, Helvetica, sans-serif',
						borderCollapse: 'collapse',
						width: '200%',
					}}
				>
					<tr style={{ width: '200px' }}>
						<th
							style={{
								border: '1px solid #ddd',
								paddingTop: '12px',
								paddingBottom: '12px',
								backgroundColor: '#04AA6D',
								color: 'white',
							}}
						>
							<input
								type="checkbox"
								style={{
									content: '',
									left: '0px',
									top: '0px',
									width: '18px',
									height: '18px',
									border: '1px solid #dddddd',
									backgroundColor: '#ffffff',
								}}
								onChange={() => setAll(!all)}
								checked={all}
							/>
						</th>
						{tables.map(el => (
							<th
								style={{
									border: '1px solid #ddd',
									padding: '15px',
									backgroundColor: '#04AA6D',
									color: 'white',
								}}
							>
								{el}
							</th>
						))}
					</tr>
					{state.map(el => (
						<tr>
							<td style={{ border: '1px solid #ddd', padding: '8px' }}>
								<input
									key={el.name}
									id={el.name}
									type="checkbox"
									style={{
										content: '',
										left: '0px',
										top: '0px',
										width: '18px',
										height: '18px',
										border: '1px solid #dddddd',
										backgroundColor: '#ffffff',
									}}
									onChange={event => changeCheckboxes(event.target.id)}
									checked={checkboxes.includes(el.name)}
								/>
							</td>
							<td
								style={{
									border: '1px solid #ddd',
									padding: '8px',
									color: `${el.local_ping_color}`,
								}}
							>
								{el.name}
							</td>
							<td style={{ border: '1px solid #ddd', padding: '8px' }}>
								{el.operator}
							</td>
							<td style={{ border: '1px solid #ddd', padding: '8px' }}>
								{el.local_ip}
							</td>
							<td style={{ border: '1px solid #ddd', padding: '8px' }}>
								{el.lte_int_ip}
							</td>
							<td style={{ border: '1px solid #ddd', padding: '8px' }}>
								{el.uptime}
							</td>
							<td style={{ border: '1px solid #ddd', padding: '8px' }}>
								{el.external_ip}
							</td>
							<td
								style={{
									border: '1px solid #ddd',
									padding: '8px',
									color: `${el.local_ping_color}`,
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-around',
									}}
								>
									{el.local_ping}
									<div
										style={{
											width: '15px',
											height: '15px',
											background: `${el.local_ping_color}`,
										}}
									/>
								</div>
							</td>
							<td
								style={{
									border: '1px solid #ddd',
									padding: '8px',
									color: `${el.external_ping_color}`,
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-around',
									}}
								>
									{el.external_ping}
									<div
										style={{
											width: '15px',
											height: '15px',
											background: `${el.external_ping_color}`,
										}}
									/>
								</div>
							</td>
						</tr>
					))}
				</table>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
				}}
			>
				{page < 31 ? null : (
					<button
						style={{
							backgroundColor: '#4CAF50',
							borderRadius: '10px',
							color: 'white',
							padding: '6px 25px',
							textAlign: 'center',
							textDecoration: 'none',
							fontSize: '30px',
						}}
						type="button"
						onClick={() => changePageHandlerMinus()}
					>
						{'<'}
					</button>
				)}
				<div
					style={{
						backgroundColor: '#4CAF50',
						borderRadius: '10px',
						color: 'white',
						padding: '6px 25px',
						textAlign: 'center',
						textDecoration: 'none',
						fontSize: '30px',
					}}
				>
					{`${page / 30} страница`}
				</div>
				{state.length < 30 ? null : (
					<button
						type="button"
						style={{
							backgroundColor: '#4CAF50',
							borderRadius: '10px',
							color: 'white',
							padding: '6px 25px',
							textAlign: 'center',
							textDecoration: 'none',
							fontSize: '30px',
						}}
						onClick={() => changePageHandlerPlus()}
					>
						{'>'}
					</button>
				)}
			</div>
		</div>
	)
}
