import axios from 'axios'
import React, { useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import type { ResultState } from '../../../../../store/slices/resultSlice/resultTypes'

type TypeOfResult = {
	result: string
}

type ResultTypeMode = {
	success: boolean
	time: number
	data: TypeOfResult
}

export default function AddMode(): JSX.Element {
	const ref = useLocation()
	console.log(ref.pathname.slice(5, 13))
	const [input, setInput] = useState('')
	const [result, setResult] = useState('')

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const submitHandler = event => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
		event.preventDefault()
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		console.log(input)
		axios<ResultTypeMode>(`https://atom.terminator.systems/api/v7/sim/scan/addmode?token=57478bdd6cc0d1aeef78b98f8f1a4220&mode=${input}
        `)
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument
			.then(res => setResult(res.data.data.result))
			.catch(console.log)
		setInput('')
	}

	return (
		<>
			<form id="form" onSubmit={submitHandler}>
				<div
					style={{
						marginTop: '30px',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						fontSize: '120%',
						fontWeight: '900',
					}}
				>
					Write an operator
				</div>
				<div
					style={{
						marginTop: '30px',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
					}}
				>
					<div>
						<input
							style={{
								marginRight: '10px',
								position: 'relative',
								boxSizing: 'border-box',
								borderRadius: '10px',
								height: 'auto',
								padding: '10px',
								fontSize: '16px',
								zIndex: '2',
							}}
							id="text"
							type="text"
							value={input}
							onChange={e => setInput(e.target.value)}
						/>
					</div>
				</div>
				<div
					style={{
						marginTop: '30px',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
					}}
				>
					<button
						style={{
							backgroundColor: '#4CAF50',
							borderRadius: '10px',
							color: 'white',
							padding: '6px 32px',
							textAlign: 'center',
							textDecoration: 'none',
							display: 'inline-block',
							fontSize: '16px',
						}}
						type="submit"
					>
						{' '}
						Add
					</button>
				</div>
			</form>
			<div
				style={{
					marginTop: '30px',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					fontSize: '120%',
					fontWeight: '900',
				}}
			>{`Информация: ${result}`}</div>
		</>
	)
}
