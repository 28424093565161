import React from 'react'

export default function Main(): JSX.Element {
	return (
		<div
			style={{
				paddingTop: '280px',
				display: 'flex',
				justifyContent: 'center',
				fontSize: '120%',
				fontWeight: '900',
			}}
		>
			Terminator Monitoring systems
		</div>
	)
}
