/* eslint-disable */
/* @ts-nocheck */
import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import {
	useAppDispatch,
	useAppSelector,
} from '../../../../../hooks/redux-hooks'
import { postInformationThunk } from '../../../../../store/slices/resultSlice/resultSlice'
import {
	ResultState,
	ResulType,
} from '../../../../../store/slices/resultSlice/resultTypes'
import { getOperatorsThunk } from '../../../../../store/slices/simSlice/scanSlice'

export default function Scan(): JSX.Element {
	const operators = useAppSelector(state => state.scan.data.modes)
	// const result = useAppSelector(state => state.result.data.simcard_info)
	const dispatch = useAppDispatch()
	const [iccid, setIccid] = useState('')
	const [mode, setMode] = useState('')
	const [results, setResults] = useState('')
	console.log(results, mode)

	useEffect(() => {
		dispatch(getOperatorsThunk())
	}, [])
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const submitHandler = e => {
		e.preventDefault()
		axios<ResultState>(
			`https://atom.terminator.systems/api/v7/sim/scan/iccid?token=57478bdd6cc0d1aeef78b98f8f1a4220&iccid=${iccid}&mode=${mode}`,
		)
			.then(res => setResults(res.data.data.simcard_info))
			.catch(console.log)
		setMode('')
		setIccid('')
	}

	return (
		<>
			<form id="form" onSubmit={submitHandler}>
				<div
					style={{
						marginTop: '30px',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
					}}
				>
					<div>
						<select
							id="selector"
							style={{
								marginRight: '10px',
								position: 'relative',
								boxSizing: 'border-box',
								borderRadius: '10px',
								height: 'auto',
								padding: '10px',
								fontSize: '16px',
								zIndex: '2',
							}}
							value={mode}
							onChange={e => setMode(e.target.value)}
						>
							<option defaultValue="Choose operator">Choose operator</option>
							{operators.map(operator => (
								<option key={operator.id} value={operator.name}>
									{operator.name}
								</option>
							))}
						</select>
					</div>
					<div>
						<input
							style={{
								marginRight: '10px',
								position: 'relative',
								boxSizing: 'border-box',
								borderRadius: '10px',
								height: 'auto',
								padding: '10px',
								fontSize: '16px',
								zIndex: '2',
							}}
							id="text"
							type="text"
							value={iccid}
							onChange={e => setIccid(e.target.value)}
							autoFocus
						/>
					</div>
				</div>
			</form>
			<div
				style={{
					marginTop: '30px',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					fontSize: '120%',
					fontWeight: '900',
				}}
			>{`Информация: ${results}`}</div>
		</>
	)
}
