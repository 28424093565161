/* eslint-disable */
/* @ts-nocheck */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import type { AppThunk } from '../../../hooks/redux-hooks'
import type { ResultState, ResulType } from './resultTypes'

const initialState: ResultState = {
	success: false,
	time: 0,
	data: {
		simcard_info: '',
	},
}

export const ResultSlice = createSlice({
	name: 'result',
	initialState,
	reducers: {
		resultInformation: (state, action: PayloadAction<ResulType>) => {
			// eslint-disable-next-line no-param-reassign
			state.data.simcard_info = action.payload.simcard_info
		},
	},
})

export const { resultInformation } = ResultSlice.actions

export const postInformationThunk =
	(iccid: string, mode: string): AppThunk =>
	dispatch => {
		axios<ResultState>(
			`https://atom.terminator.systems/api/v7/sim/scan/iccid?token=57478bdd6cc0d1aeef78b98f8f1a4220&iccid=${iccid}&mode=${mode}`,
		)
			.then(res => {
				dispatch(resultInformation(res.data.data))
			})
			.catch(error => {
				console.log('ОШИБКА!!!', error)
			})
	}

export default ResultSlice.reducer
