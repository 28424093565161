/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { Link, useLocation } from 'react-router-dom'
import {
	createTheme,
	Menu,
	MenuItem,
	Tooltip,
	ThemeProvider,
} from '@mui/material'
import { green } from '@mui/material/colors'

const theme = createTheme({
	palette: {
		primary: {
			main: green[800],
		},
		secondary: {
			main: '#388e3c',
		},
	},
})

export default function NavBar(): JSX.Element {
	const { pathname } = useLocation()
	const [acconts, setAccounts] = React.useState(null)
	const [anchorElNavSim, setAnchorElNavSim] = React.useState(null)
	const [anchorElNavConv, setAnchorElNavConv] = React.useState(null)
	const [anchorElNav, setAnchorElNav] = React.useState(null)
	const [anchorElUser, setAnchorElUser] = React.useState(null)
	// @ts-ignore
	const handleOpenAccountsMenu = event => {
		setAccounts(event.currentTarget)
	}
	// @ts-ignore
	const handleOpenSimMenu = event => {
		setAnchorElNavSim(event.currentTarget)
	}
	// @ts-ignore
	const handleOpenMenu = event => {
		setAnchorElNavConv(event.currentTarget)
	}
	// @ts-ignore
	const handleOpenNavMenu = event => {
		setAnchorElNav(event.currentTarget)
	}
	// @ts-ignore
	const handleOpenUserMenu = event => {
		setAnchorElUser(event.currentTarget)
	}
	const handleCloseAccontsMenu = () => {
		setAccounts(null)
	}
	const handleCloseSimMenu = () => {
		setAnchorElNavSim(null)
	}
	const handleCloseMenu = () => {
		setAnchorElNavConv(null)
	}
	const handleCloseNavMenu = () => {
		setAnchorElNav(null)
	}
	const handleCloseUserMenu = () => {
		setAnchorElUser(null)
	}
	return (
		<ThemeProvider theme={theme}>
			<Box sx={{ flexGrow: 3 }}>
				<AppBar position="static">
					<Toolbar>
						<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
							{pathname === '/sim/scan'
								? 'Sim Scan'
								: pathname === '/sim/sms'
								? 'Sim SMS'
								: pathname === '/sim/activate'
								? 'Sim Activate'
								: pathname === '/sim/add-mode'
								? 'Sim Add mode'
								: pathname === '/andriod/current'
								? 'Android Online'
								: pathname === '/andriod/plan'
								? 'Android Plan'
								: pathname === '/andriod/protocol'
								? 'Android Protocols'
								: pathname === '/andriod/tests'
								? 'Android Tests'
								: pathname === '/network/lte'
								? 'Network LTE'
								: pathname === '/network/wifi'
								? 'Network WIFI'
								: pathname === '/conversations/telegram'
								? 'Conversations Telegram'
								: pathname === '/conversations/whatsapp'
								? 'Conversations Whatsapp'
								: pathname === '/conversations/online'
								? 'Conversations Online'
								: pathname === '/stats/accounts'
								? 'Stats Accounts'
								: 'Main'}
						</Typography>
						<Box sx={{ flexGrow: 0 }}>
							<Tooltip title="Открыть меню">
								<IconButton color="inherit" onClick={handleOpenNavMenu}>
									Android
								</IconButton>
							</Tooltip>
							<Menu
								sx={{ mt: '45px' }}
								id="menu-appbar"
								anchorEl={anchorElNav}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
							>
								<Link
									to="/andriod/current"
									style={{ color: 'black', textDecoration: 'none' }}
								>
									<MenuItem>
										<Typography textAlign="center">Online</Typography>
									</MenuItem>
								</Link>
								<Link
									to="/andriod/plan"
									style={{ color: 'black', textDecoration: 'none' }}
								>
									<MenuItem>
										<Typography textAlign="center">Queue</Typography>
									</MenuItem>
								</Link>
								<Link
									to="/andriod/protocol"
									style={{ color: 'black', textDecoration: 'none' }}
								>
									<MenuItem>
										<Typography textAlign="center">Protocols</Typography>
									</MenuItem>
								</Link>
								<Link
									to="/andriod/tests"
									style={{ color: 'black', textDecoration: 'none' }}
								>
									<MenuItem>
										<Typography textAlign="center">Tests</Typography>
									</MenuItem>
								</Link>
							</Menu>
						</Box>
						<Box sx={{ flexGrow: 0 }}>
							<Tooltip title="Открыть меню">
								<IconButton color="inherit" onClick={handleOpenUserMenu}>
									Network
								</IconButton>
							</Tooltip>
							<Menu
								sx={{ mt: '45px' }}
								id="menu-appbar"
								anchorEl={anchorElUser}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(anchorElUser)}
								onClose={handleCloseUserMenu}
							>
								<Link
									to="/network/lte"
									style={{ color: 'black', textDecoration: 'none' }}
								>
									<MenuItem>
										<Typography textAlign="center">LTE</Typography>
									</MenuItem>
								</Link>
								<Link
									to="/network/wifi"
									style={{ color: 'black', textDecoration: 'none' }}
								>
									<MenuItem>
										<Typography textAlign="center">WIFI</Typography>
									</MenuItem>
								</Link>
							</Menu>
						</Box>
						<Box sx={{ flexGrow: 0 }}>
							<Tooltip title="Открыть меню">
								<IconButton color="inherit" onClick={handleOpenSimMenu}>
									Sim
								</IconButton>
							</Tooltip>
							<Menu
								sx={{ mt: '45px' }}
								id="menu-appbar"
								anchorEl={anchorElNavSim}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(anchorElNavSim)}
								onClose={handleCloseSimMenu}
							>
								<Link
									to="/sim/activate"
									style={{ color: 'black', textDecoration: 'none' }}
								>
									<MenuItem>
										<Typography textAlign="center">Activate</Typography>
									</MenuItem>
								</Link>
								<Link
									to="/sim/scan"
									style={{ color: 'black', textDecoration: 'none' }}
								>
									<MenuItem>
										<Typography textAlign="center">Scan</Typography>
									</MenuItem>
								</Link>
								<Link
									to="/sim/sms"
									style={{ color: 'black', textDecoration: 'none' }}
								>
									<MenuItem>
										<Typography textAlign="center">SMS</Typography>
									</MenuItem>
								</Link>
								<Link
									to="/sim/add-mode"
									style={{ color: 'black', textDecoration: 'none' }}
								>
									<MenuItem>
										<Typography textAlign="center">Add mode</Typography>
									</MenuItem>
								</Link>
							</Menu>
						</Box>
						<Box sx={{ flexGrow: 0 }}>
							<Tooltip title="Открыть меню">
								<IconButton color="inherit" onClick={handleOpenMenu}>
									Conversations
								</IconButton>
							</Tooltip>
							<Menu
								sx={{ mt: '45px' }}
								id="menu-appbar"
								anchorEl={anchorElNavConv}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(anchorElNavConv)}
								onClose={handleCloseMenu}
							>
								<Link
									to="/conversations/telegram"
									style={{ color: 'black', textDecoration: 'none' }}
								>
									<MenuItem>
										<Typography textAlign="center">Telegram</Typography>
									</MenuItem>
								</Link>
								<Link
									to="/conversations/whatsapp"
									style={{ color: 'black', textDecoration: 'none' }}
								>
									<MenuItem>
										<Typography textAlign="center">Whatsapp</Typography>
									</MenuItem>
								</Link>
								<Link
									to="/conversations/online"
									style={{ color: 'black', textDecoration: 'none' }}
								>
									<MenuItem>
										<Typography textAlign="center">Online</Typography>
									</MenuItem>
								</Link>
							</Menu>
						</Box>
						<Box sx={{ flexGrow: 0 }}>
							<Tooltip title="Открыть меню">
								<IconButton color="inherit" onClick={handleOpenAccountsMenu}>
									Stats
								</IconButton>
							</Tooltip>
							<Menu
								sx={{ mt: '45px' }}
								id="menu-appbar"
								anchorEl={acconts}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(acconts)}
								onClose={handleCloseAccontsMenu}
							>
								<Link
									to="/stats/accounts"
									style={{ color: 'black', textDecoration: 'none' }}
								>
									<MenuItem>
										<Typography textAlign="center">Accounts</Typography>
									</MenuItem>
								</Link>
							</Menu>
						</Box>
					</Toolbar>
				</AppBar>
			</Box>
		</ThemeProvider>
	)
}
