import { configureStore } from '@reduxjs/toolkit'
import IpTableReducer from './slices/ipSlice/ipSlice'
import SimScanReducer from './slices/simSlice/scanSlice'
import ResultReducer from './slices/resultSlice/resultSlice'

export const store = configureStore({
	reducer: {
		ips: IpTableReducer,
		scan: SimScanReducer,
		result: ResultReducer,
	},
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
