import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import type { AppThunk } from '../../../hooks/redux-hooks'
import type { ScanSimState } from './scanTypes'

const initialState: ScanSimState = {
	success: false,
	time: 0,
	data: {
		modes: [],
	},
}

export const SimScanSlice = createSlice({
	name: 'scan',
	initialState,
	reducers: {
		getOperators: (state, action: PayloadAction<ScanSimState>) => {
			// eslint-disable-next-line no-param-reassign
			state.data.modes = action.payload.data.modes
		},
	},
})

export const { getOperators } = SimScanSlice.actions

export const getOperatorsThunk = (): AppThunk => dispatch => {
	axios<ScanSimState>(
		'https://atom.terminator.systems/api/v7/sim/scan/modes?token=57478bdd6cc0d1aeef78b98f8f1a4220',
	)
		.then(res => dispatch(getOperators(res.data)))
		.catch(error => {
			console.log('ОШИБКА!!!', error)
		})
}

export default SimScanSlice.reducer
