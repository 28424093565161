import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import type { AppThunk } from '../../../hooks/redux-hooks'
import type { IpTableTypeState } from './ipTypes'

const initialState: IpTableTypeState = {
	ips: {
		success: false,
		columns: [],
		data: [],
		time: 0,
	},
}

export const IpTableSlice = createSlice({
	name: 'ips',
	initialState,
	reducers: {
		getIp: (state, action: PayloadAction<IpTableTypeState>) => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// eslint-disable-next-line no-param-reassign
			state.ips = action.payload
		},
	},
})

export const { getIp } = IpTableSlice.actions

export const allIps = (): AppThunk => dispatch => {
	axios<IpTableTypeState>(
		'https://atom.terminator.systems/api/v7/network/lte/list?token=57478bdd6cc0d1aeef78b98f8f1a4220',
	)
		.then(res => dispatch(getIp(res.data)))
		.catch(error => {
			console.log(error)
		})
}

export default IpTableSlice.reducer

// {
// 	headers: {
// 		'Content-Type': 'application/json',
// 		'Access-Control-Allow-Origin': 'http://localhost:3000',
// 		'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
// 		'Access-Control-Allow-Headers': '*',
// 	},
// },
